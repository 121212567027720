// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-khoj-about-js": () => import("./../../../src/pages/khoj/about.js" /* webpackChunkName: "component---src-pages-khoj-about-js" */),
  "component---src-pages-khoj-contribute-js": () => import("./../../../src/pages/khoj/contribute.js" /* webpackChunkName: "component---src-pages-khoj-contribute-js" */),
  "component---src-pages-khoj-dashboard-index-js": () => import("./../../../src/pages/khoj/dashboard/index.js" /* webpackChunkName: "component---src-pages-khoj-dashboard-index-js" */),
  "component---src-pages-khoj-explore-js": () => import("./../../../src/pages/khoj/explore.js" /* webpackChunkName: "component---src-pages-khoj-explore-js" */),
  "component---src-pages-khoj-index-js": () => import("./../../../src/pages/khoj/index.js" /* webpackChunkName: "component---src-pages-khoj-index-js" */),
  "component---src-pages-khoj-search-hidden-js": () => import("./../../../src/pages/khoj/search-hidden.js" /* webpackChunkName: "component---src-pages-khoj-search-hidden-js" */),
  "component---src-pages-khoj-search-old-js": () => import("./../../../src/pages/khoj/search-old.js" /* webpackChunkName: "component---src-pages-khoj-search-old-js" */),
  "component---src-pages-khoj-status-js": () => import("./../../../src/pages/khoj/status.js" /* webpackChunkName: "component---src-pages-khoj-status-js" */)
}

